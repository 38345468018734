import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import './AddOrEditPurchase.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../contexts/AuthContext';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

function AddOrEditPurchase({ isEdit }) {
  const { id } = useParams();
  const { token } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [purchase, setPurchase] = useState({
    id: '',
    amount: '',
    currency: 'usd',
    status: 'succeeded',
    email: '',
    name: '',
    shipping: {
      address: {
        city: '',
        country: '',
        line1: '',
        line2: '',
        postal_code: '',
        state: ''
      },
      name: ''
    },
    payment_intent: '',
    shipping_id: '',
    shipping_status: '',
    items: [], // Initialize items to an empty array
    custom_fields: []
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${STORE_BACKEND_URL}/users/products`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
        const data = await response.json();
        const filteredProducts = data.filter(product => product.type !== 'affiliate');
        setProducts(filteredProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const fetchPurchase = async () => {
      if (isEdit) {
        try {
          const response = await fetch(`${STORE_BACKEND_URL}/purchases/${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          });
          const data = await response.json();
          setPurchase({
            ...data,
            custom_fields: data.custom_fields || []
          });
        } catch (error) {
          console.error('Error fetching purchase details:', error);
        }
      }
    };

    if (token) {
      fetchProducts();
      fetchPurchase();
    }
  }, [isEdit, id, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [section, field] = name.split('.');

    if (section === 'address') {
      setPurchase((prevPurchase) => ({
        ...prevPurchase,
        shipping: {
          ...prevPurchase.shipping,
          address: {
            ...prevPurchase.shipping.address,
            [field]: value || '', // Ensure value is not null
          },
        },
      }));
    } else {
      setPurchase((prevPurchase) => ({
        ...prevPurchase,
        [name]: value || '', // Ensure value is not null
      }));
      if (name === 'name') {
        setPurchase((prevPurchase) => ({
          ...prevPurchase,
          shipping: {
            ...prevPurchase.shipping,
            name: value || '', // Ensure value is not null
          },
        }));
      }
    }
  };

  // This function adjusts the quantity directly from the quantity input field.
  const handleQuantityChange = (productId, quantity) => {
    setPurchase(prevPurchase => {
      const items = prevPurchase.items.map(item =>
        item.price.product === productId ? { ...item, quantity: Math.max(quantity, 0) } : item
      );
      return {
        ...prevPurchase,
        items,
      };
    });
  };

  // This function will now check if an item is already in the list, and if so, 
  // it will allow the user to change the quantity instead of only adding or removing the item.
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const selectedProduct = products.find(product => product.id === value);
  
    setPurchase((prevPurchase) => {
      let newItem = {
        id: `li_${Math.random().toString(36).substr(2, 9)}`,
        object: "item",
        amount_discount: 0,
        amount_subtotal: selectedProduct.prices[0].unit_amount,
        amount_tax: 0,
        amount_total: selectedProduct.prices[0].unit_amount,
        currency: selectedProduct.prices[0].currency,
        description: selectedProduct.name,
        price: {
          id: selectedProduct.id,
          object: "price",
          active: true,
          billing_scheme: "per_unit",
          created: selectedProduct.prices[0].created,
          currency: selectedProduct.prices[0].currency,
          custom_unit_amount: null,
          livemode: true,
          lookup_key: null,
          metadata: {},
          nickname: null,
          product: selectedProduct.id,
          recurring: null,
          tax_behavior: "unspecified",
          tiers_mode: null,
          transform_quantity: null,
          type: "one_time",
          unit_amount: selectedProduct.prices[0].unit_amount,
          unit_amount_decimal: `${selectedProduct.prices[0].unit_amount}`
        },
        quantity: 1
      };
  
      const existingItemIndex = prevPurchase.items.findIndex(item => item.price.product === value);
      if (existingItemIndex !== -1) {
        newItem = { ...prevPurchase.items[existingItemIndex] };
        newItem.quantity = checked ? newItem.quantity + 1 : newItem.quantity - 1;
        newItem.quantity = Math.max(newItem.quantity, 0); // Prevent negative quantities
      }
  
      const items = existingItemIndex !== -1
        ? [
            ...prevPurchase.items.slice(0, existingItemIndex),
            ...(newItem.quantity > 0 ? [newItem] : []),
            ...prevPurchase.items.slice(existingItemIndex + 1)
          ]
        : [...prevPurchase.items, newItem];
      return {
        ...prevPurchase,
        items,
      };
    });
  };  

  /*
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setPurchase((prevPurchase) => {
      const selectedProduct = products.find(product => product.id === value);

      const newItem = {
        id: `li_${Math.random().toString(36).substr(2, 9)}`,
        object: "item",
        amount_discount: 0,
        amount_subtotal: selectedProduct.prices[0].unit_amount,
        amount_tax: 0,
        amount_total: selectedProduct.prices[0].unit_amount,
        currency: selectedProduct.prices[0].currency,
        description: selectedProduct.name,
        price: {
          id: selectedProduct.id,
          object: "price",
          active: true,
          billing_scheme: "per_unit",
          created: selectedProduct.prices[0].created,
          currency: selectedProduct.prices[0].currency,
          custom_unit_amount: null,
          livemode: true,
          lookup_key: null,
          metadata: {},
          nickname: null,
          product: selectedProduct.id,
          recurring: null,
          tax_behavior: "unspecified",
          tiers_mode: null,
          transform_quantity: null,
          type: "one_time",
          unit_amount: selectedProduct.prices[0].unit_amount,
          unit_amount_decimal: `${selectedProduct.prices[0].unit_amount}`
        },
        quantity: 1
      };
      const items = checked
        ? [...prevPurchase.items, newItem]
        : prevPurchase.items.filter(item => item.price.product !== value);
      return {
        ...prevPurchase,
        items,
      };
    });
  };
  */

  const handleCustomFieldChange = (index, e) => {
    const { name, value } = e.target;
    setPurchase((prevPurchase) => {
      const newCustomFields = [...prevPurchase.custom_fields];
      if (name.includes('.')) {
        const [section, field] = name.split('.');
        newCustomFields[index] = {
          ...newCustomFields[index],
          [section]: {
            ...newCustomFields[index][section],
            [field]: value || '' // Ensure value is not null
          }
        };
      } else {
        newCustomFields[index] = {
          ...newCustomFields[index],
          [name]: value || '' // Ensure value is not null
        };
      }
      return {
        ...prevPurchase,
        custom_fields: newCustomFields,
      };
    });
  };

  const handleAddCustomField = () => {
    setPurchase((prevPurchase) => ({
      ...prevPurchase,
      custom_fields: [
        ...prevPurchase.custom_fields,
        {
          key: '',
          label: {
            custom: '',
            type: 'custom'
          },
          optional: true,
          text: {
            default_value: null,
            maximum_length: null,
            minimum_length: null,
            value: ''
          },
          type: 'text'
        }
      ]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userConfirmed = window.confirm('Are you sure you want to update?');
    if (userConfirmed) {
      try {
        const method = isEdit ? 'PUT' : 'POST';
        const endpoint = isEdit ? `${STORE_BACKEND_URL}/purchases/${id}` : `${STORE_BACKEND_URL}/purchases`;
        const response = await fetch(endpoint, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(purchase),
        });

        if (response.ok) {
          toast.success(isEdit ? "Purchase Updated" : "Purchase Added");
        } else {
          const errorData = await response.json();
          toast.error("Error saving purchase: " + JSON.stringify(errorData));
        }
      } catch (error) {
        toast.error("Error saving purchase: " + error);
      }
    }
  };

  const purchasedProductIds = purchase.items.map(item => item.price.product);

  return (
    <div className="theme-container">
      <ToastContainer />
      <Link to={`/purchases`}>Back to Purchases</Link>
      <h2>{isEdit ? 'Edit Purchase' : 'Add New Purchase'}</h2>
      {isEdit && <p>Editing purchase with ID: {id}</p>}
      <div className="add-purchase">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Amount:</label>
            <input type="text" name="amount" value={purchase.amount || ''} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Currency:</label>
            <input type="text" name="currency" value={purchase.currency || ''} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input type="email" name="email" value={purchase.email || ''} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Name:</label>
            <input type="text" name="name" value={purchase.name || ''} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Shipping Address Line 1:</label>
            <input type="text" name="address.line1" value={purchase.shipping.address.line1 || ''} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Shipping Address Line 2:</label>
            <input type="text" name="address.line2" value={purchase.shipping.address.line2 || ''} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>City:</label>
            <input type="text" name="address.city" value={purchase.shipping.address.city || ''} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>State:</label>
            <input type="text" name="address.state" value={purchase.shipping.address.state || ''} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Postal Code:</label>
            <input type="text" name="address.postal_code" value={purchase.shipping.address.postal_code || ''} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Country:</label>
            <select name="address.country" value={purchase.shipping.address.country || ''} onChange={handleChange}>
              <option value="">Select Country</option>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="GB">United Kingdom</option>
              <option value="AU">Australia</option>
              <option value="DE">Germany</option>
              <option value="FR">France</option>
              <option value="IN">India</option>
              <option value="JP">Japan</option>
              <option value="CN">China</option>
              <option value="MY">Malaysia</option>
            </select>
          </div>
          <div className="form-group">
            <label>Items:</label>
            <div className="checkbox-group">
              {products.map(product => (
                <div key={product.id} className="checkbox-item">
                  <input
                    className='checkbox'
                    type="checkbox"
                    value={product.id}
                    onChange={handleCheckboxChange}
                    checked={purchasedProductIds.includes(product.id)}
                  />
                  {product.name}
                  <div style={{ width: '100px' }}>
                  {purchasedProductIds.includes(product.id) && (
                    <input
                      type="number"
                      min="0"
                      value={purchase.items.find(item => item.price.product === product.id)?.quantity || 0}
                      onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value))}
                    />
                  )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="form-group">
            <label>Custom Fields:</label>
            <div className="custom-field-group">
            {(purchase.custom_fields || []).map((field, index) => (
              <div key={index} className="custom-field">
                <input
                  type="text"
                  name="key"
                  placeholder="Key"
                  value={field.key || ''}
                  onChange={(e) => handleCustomFieldChange(index, e)}
                />
                <input
                  type="text"
                  name="label.custom"
                  placeholder="Title"
                  value={field.label.custom || ''}
                  onChange={(e) => handleCustomFieldChange(index, e)}
                />
                <input
                  type="text"
                  name="text.value"
                  placeholder="Value"
                  value={field.text.value || ''}
                  onChange={(e) => handleCustomFieldChange(index, e)}
                />
              </div>
            ))}
            <button type="button" onClick={handleAddCustomField}>+</button>
            </div>
          </div>
          <button type="submit">{isEdit ? 'Update Purchase' : 'Add Purchase'}</button>
        </form>
      </div>
    </div>
  );
}

export default AddOrEditPurchase;
